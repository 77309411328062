const EXTRACTORS = [
  { pattern: "youtube\\.com\\/(embed|v)\\/([\\w-]+)", provider: "youtube" },
  { pattern: "youtube\\.com\\/watch\\?v=([\\w-]+)", provider: "youtube" },
  { pattern: "youtu.be\\/([\\w-]+)", provider: "youtube" },
  { pattern: "vimeo\\.com\\/video\\/(\\d+)", provider: "vimeo" },
  { pattern: "vimeo\\.com\\/(\\d+)", provider: "vimeo" },
  { pattern: "vimeo\\.com\\/moogaloop\\.swf\\?clip_id=(\\d+)", provider: "vimeo" },
]

const IFRAME_SRC = {
  youtube: (id: string): string => `//www.youtube.com/embed/${id}`,
  vimeo: (id: string): string => `//player.vimeo.com/video/${id}`,
}

export const link2src = (link: string): string | undefined => {
  let src: string
  EXTRACTORS.some(({ pattern, provider }) => {
    const regex = new RegExp(pattern, "i")
    const matches = link.match(regex)
    if (matches) {
      src = IFRAME_SRC[provider](matches[matches.length - 1])
      return true
    }
    return false
  })
  return src
}
