import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { GatsbyPage, ITutorial } from "src/types"
import Layout from "src/layout"
import Image from "src/components/Image"
import { Section, Subtitle, Title, Description, Text, Content } from "src/components/styled"
import { SIZES, BREAKPOINTS } from "src/constants"
import { link2src } from "src/helpers/video"
import { Splash, Parallax } from "src/components/Parallax"
import RichText from "src/components/RichText"
import { scrollToRef } from "src/helpers/window"

const Tutorials = styled.div`
  margin: 6em 0;
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    margin: 4em 0;
  }
`
const TutorialTitle = styled.h3`
  font-size: ${SIZES.large};
  margin-top: 2em;
`
const Video = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`
const Iframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
`

const TutorialsPage: GatsbyPage = ({ data }) => {
  return (
    <Layout title="Tutoriels">
      <Splash>
        <Parallax desktop={data.splashDesktop.childImageSharp} mobile={data.splashMobile.childImageSharp} />
      </Splash>
      <Content>
        <Section>
          <Subtitle>Apprenez à bricoler en suivant nos tutos</Subtitle>
          <Title ref={scrollToRef}>
            <span>Voici quelques mobiliers réalisés dans nos ateliers pour vous donner envie de bricoler !</span>
          </Title>
          <Description>
            Ces tutos peuvent être réalisés chez vous ou dans nos ateliers. Ils sont filmés en collaboration avec la
            chaine youtube 18h39. C’est l’occasion d’apprendre à bricoler et de découvrir en même temps l’univers des
            Ateliers Chutes Libres.
          </Description>
        </Section>
        <Tutorials>
          {data.tutoriels.nodes.map(({ id, title, link, image, description }: ITutorial) => {
            const iframeSrc = link2src(link)
            return (
              <Section key={id}>
                <TutorialTitle>{title}</TutorialTitle>
                {iframeSrc ? (
                  <Video>
                    <Iframe
                      src={iframeSrc}
                      frameBorder={0}
                      allowFullScreen
                      allow="encrypted-media; picture-in-picture"
                    />
                  </Video>
                ) : (
                  <a href={link} target="_blank" rel="noopener">
                    <Image file={image} />
                  </a>
                )}
                <Text as="div">
                  <RichText node={description} />
                </Text>
              </Section>
            )
          })}
        </Tutorials>
      </Content>
    </Layout>
  )
}

export default TutorialsPage

export const query = graphql`
  query {
    splashDesktop: file(relativePath: { eq: "tutos/splash-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    splashMobile: file(relativePath: { eq: "tutos/splash-mobile.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tutoriels: allContentfulTutorial(sort: { fields: [position], order: ASC }) {
      nodes {
        id: contentful_id
        title
        link
        image {
          fluid(maxWidth: 1160) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          json
        }
      }
    }
  }
`
