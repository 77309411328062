import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { getScroll } from "src/helpers/window"
import Image, { ResponsiveImageSwitcher } from "src/components/Image"
import { ContentfulImage } from "src/types"

export const Splash = styled.div`
  height: calc(100vh - 55px);
  .gatsby-image-wrapper {
    max-height: 100%;
  }
`

interface Props {
  desktop: ContentfulImage
  mobile: ContentfulImage
}

export const Parallax: React.FC<Props> = ({ desktop, mobile }) => {
  const [bgOffset, setBgOffset] = useState(0)
  const [height, setHeight] = useState<number>() // because viewport height can change due to mobile nav hiding

  useEffect(() => {
    if (window.innerHeight > window.innerWidth) {
      setHeight(window.innerHeight)
    }
    const handleScroll = (): void => {
      setBgOffset(getScroll() / 2)
    }
    document.addEventListener("scroll", handleScroll, { passive: true })
    return (): void => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const splashBgStyle: React.CSSProperties = {
    position: "fixed",
    top: 55,
    left: 0,
    right: 0,
    transform: `translateY(${-bgOffset}px)`,
  }

  if (height) {
    splashBgStyle.height = height
  } else {
    splashBgStyle.bottom = 0
  }

  return (
    <ResponsiveImageSwitcher>
      <Image file={mobile} className="mobile" style={splashBgStyle} />
      <Image file={desktop} className="desktop" style={splashBgStyle} />
    </ResponsiveImageSwitcher>
  )
}
